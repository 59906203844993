import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/wisdom-for-wisdom.scss"
import "../css/pages/wisdom.scss"

// const video = require("../_archives/procedures/wisdom-teeth-removal.json")

import "../css/pages/w4w.scss"

const W4W = () => {
  const image = name => `/OKC/DEV/${name}.png`
  return (
    <Layout pageTitle="wisdom-page" language={"en"}>
      <SEO
        lang="en"
        title="Learn about our Wisdom For Wisdom program"
        description="Follow the Wisdom For Wisdom steps to apply"
      />

      <div className="w4w-23">
        <div className="w4w-23__container">
          <div className="w4w-23__hero">
            <img
              className="w4w-23__hero--logo"
              src={image("2022_W4W_Logo-01")}
              alt="wisdom for wisdom program logo"
            />
            <h1>
              HELPING
              <br />
              STUDENTS
              <br />
              SAVE MONEY
              <br />
              FOR COLLEGE
            </h1>

            <div>
              <img
                className="w4w-23__hero--desktop"
                src={image("2023-w4w-hero-image")}
                alt="wisdom for wisdom grid art"
              />

              <img
                className="w4w-23__hero--mobile"
                src={image("2023-hero-image-mobile")}
                alt="wisdom for wisdom grid art"
              />
            </div>
          </div>
        </div>

        <div className="w4w-23__section">
          <div className="w4w-23__container">
            <h2>Saving for College? We Want to Help!</h2>
            <p>We’re making college more affordable for one local student.</p>

            <p>
              Our 2023 Wisdom For Wisdom program is here! This program helps to
              offset some of the costs of college by providing free wisdom teeth
              removal to one student in the Oklahoma City area.
            </p>

            <p>
              Are you saving money for college and need wisdom teeth removed?
              Submit an application and we might select you to receive a $500
              scholarship and a FREE wisdom teeth removal procedure. Apply now
              or share this information with a student today!
            </p>

            <p>
              <b>
                Thank you for your interest in our Wisdom For Wisdom program.
                Our 2023 application period is closed, but stay tuned on our
                Facebook page to hear about updates!
              </b>
            </p>
          </div>
        </div>

        <div className="w4w-23__rounded-container">
          <div className="w4w-23__section">
            <div className="w4w-23__container">
              <div>
                <div>
                  <h3>The Facts...</h3>
                  <ul>
                    <li>
                      Wisdom teeth are the only teeth that don’t form before
                      birth.
                    </li>
                    <li>
                      9 out of 10 people will have at least one wisdom tooth
                      that is impacted.
                    </li>
                    <li>Wisdom teeth are also known as third molars.</li>
                    <li>
                      Wisdom teeth got their nickname because they often grow in
                      during the “age of wisdom” usually between the ages of 17
                      and 25.
                    </li>
                    <li>
                      Wisdom teeth growing in at the wrong angle may press
                      against other teeth, causing them to become misaligned.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default W4W
